<template>
  <div>

    <b-modal
      id="modal-medicine"
      size="xl"
      title="Pilih Obat"
      hide-footer
    >
      <Table
        purpose="modal"
        modalPurpose="modal"
        :isHospitalTransaction="isHospitalTransaction"
        :selectedItem="selectedItem"
        :checkbox="checkbox"
        :isSortcut ="isSortcut"
        :isAbjad ="isAbjad"
        @chooseMedicine="chosenMedicine"
        @packageMedicine="packageMedicine"
        :secondPurpose="secondPurpose"
      />
    </b-modal>

  </div>
</template>

<script>

import Table from '@/component/medicine/Table.vue'

export default {

  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    isAbjad: Boolean,
    secondPurpose:{
      type: String,
      default: 'listing'
    }
  },

  components: {
    Table
  },

  methods: {

    chosenMedicine(value) {
      this.$emit('chosenMedicine', value)
    },
    
   packageMedicine(datas) {
      this.$emit("packageMedicine", datas);      
    },


  },
  mounted(){
    console.log(this.isSortcut,"sortcut");
    
  }

 

}
</script>

<style>
</style>