<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Stok Obat'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'medicine-stocks'"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/component/medicine/MaintainStockForm.vue";

export default {
  name: "Add",

  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {
        medicine_id: null,
        medicine_name: "",
        stock_in_out: 0,
        quantity: 0,
        remaining_stock: null,
        price: null,
        vendor_id: null,
        vendor_name: "",
        batch: "",
        notes: "",
        batch: "",
        hna: 0,
        ppn: 10,
        discount: 0,
        ref_no: "",
        source: "Klinik"
      },
      
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Obat", route: "/medicine/list" },
      { title: "Stok Obat", route: "/medicine/maintain-stock" },
      { title: "Tambah" },
    ]);
  },
};
</script>

<style>
</style>